import { Image } from "./image";
import React, { useState } from "react";
import ProductPage from "./ProductPage"; // Adjust the path as necessary
import Modal from "react-modal";

export const Gallery = (props) => {
  console.log("Gallery: ", props);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState();

  const openModalWithProduct = (product) => {
    console.log("Trying to open modal with product", product);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null); // Optional: Reset selected product on close
  };

  return (
    <>
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Galleri</h2>
            <p>
              Her er et utvalg av lysene jeg har laget. Klikk på bildene for å
              se. Kontakt meg for bestilling! :-)
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      className="col-sm-6 col-md-4 col-lg-4"
                    >
                      <img
                        key={i}
                        src={d.smallImage} // Assuming your product has a 'thumbnail' field
                        alt={d.title}
                        onClick={() => openModalWithProduct(d)}
                        className="img-responsive"
                        style={{ cursor: "pointer" }} // Makes it clear the image is clickable
                      />
                      {/* <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      onClick={() => openModalWithProduct(d)}
                      style={{ cursor: "pointer" }} // Makes it clear the image is clickable
                    /> */}
                    </div>
                  ))
                : "Loading..."}
            </div>
          </div>
          {selectedProduct && (
            <ProductPage
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              product={selectedProduct}
            />
          )}
        </div>
      </div>
    </>
  );
};
