import React from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const ProductPage = ({ isOpen, onRequestClose, product }) => {
  console.log("ProductPage -> product", product);
  console.log("ProductPage -> isOpen", isOpen);

  // return (
  //   <Modal isOpen={true} onRequestClose={() => {}}>
  //     <h2>Test Modal</h2>
  //     <h2>Test Modal</h2>
  //     <h2>Test Modal</h2>
  //     <h2>Test Modal</h2>
  //     <button onClick={() => onRequestClose(false)}>Close</button>
  //   </Modal>
  // );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // className="Modal"
      // overlayClassName="Overlay"
    >
      <div className="ProductPage">
        <h2> {` `}</h2>
        <br /> <br />
        <h2>{product.title}</h2>
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true}>
          {product.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Product ${index}`} />
            </div>
          ))}
        </Carousel>
        <div className="ProductDetails">
          <p>{product.description}</p>
          <p className="Price">{`Pris: ${product.price},- kroner`}</p>
          {/* Add to Cart Button or other actions can go here */}
        </div>
      </div>
    </Modal>
  );
};

export default ProductPage;
