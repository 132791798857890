import React from "react";
import logo from "../logo.svg";

const Logo = () => (
  <img
    src={logo}
    alt="Logo"
    style={{
      position: "relative",
      top: -15,
      marginRight: 10,
    }}
  />
);

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{ marginBottom: 20 }}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div style={{ display: "flex" }}>
            <a
              className="navbar-brand page-scroll"
              href="#page-top"
              // style={{ display: "flex" }}
            >
              <Logo />
              {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontSize: "20px" }}>Candles Of Norway</span>
                <span style={{ fontSize: "10px" }}>Håndlaget stemningslys</span>
              </div> */}
            </a>{" "}
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll">
                Om Oss
              </a>
            </li>
            {/* <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li> */}
            <li>
              <a href="#portfolio" className="page-scroll">
                Galleri
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Anmeldelser
              </a>
            </li>
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
